.date {
    color: #2a1161;
    font-style: oblique;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 10px;
}

.paragraph {
    text-align: left;
}