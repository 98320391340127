.projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 200px;
}

.gif-container {
    background-color: black;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects .project-title {
    text-align: center;
    color: #3e497a;
    font-size: 60px;
    font-weight: bolder;
}

.project-list {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    animation: fadeIn 1s ease-in-out;
}

/* MENU ITEM STYLING */

.project-item {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    margin: 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.project-item:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
}

.project-item .bgImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.project-item h1 {
    font-size: 25px;
}

@media only screen and (max-width: 1300px) {
    .gif-container img {
        width: 500px;
        height: 400px;
    }

    .project-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .gif-container img {
        width: 400px;
        height: 300px;
    }

    .project-list {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .project-list {
        width: 100%;
    }

    .project-item {
        width: 300px;
        height: 300px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0.8;
        transform: translateY(-2rem);
    }
}

@media only screen and (max-width: 600px) {
    .gif-container img {
        width: 400px;
        height: 300px;
    }
}

@media only screen and (max-width: 450px) {
    .gif-container img {
        width: 300px;
        height: 250px;
    }
}