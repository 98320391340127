.footer {
    width: 100%;
    height: 200px;
    background: #2a1161;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.social-media svg {
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
}

.icons:hover {
    color: gold;
    transform: scale(1.1);
}

.footer p {
    color: white;
}

.social-media .steam {
    width: 70px;
    height: 70px;
}

@media only screen and (max-width: 600px) {
    .footer svg {
        font-size: 50px;
    }

    .social-media .steam {
        width: 50px;
        height: 50px;
    }

}