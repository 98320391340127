.project {
    width: 100%;
    height: calc(140vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 12.5rem;
}

.project h1 {
    margin-top: 10rem;
    color: #3e497a;
    font-size: 35px;
    margin-bottom: 4rem;
}

.project img {
    width: 31rem;
    height: 31rem;
    border-radius: 10px;
    border-bottom: 5rem;
}

.project p {
    width: 80%;
    font-size: 40px;
    color: #3e497a;
}

.project .activity {
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 25px;
    color: #3e497a;
}

.project svg {
    font-size: 60px;
    color: #3e497a;
    cursor: pointer;
}

@media only screen and (max-width: 900px) {

    .project {
        height: calc(120vh - 100px);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .project h1 {
        margin-top: 10rem;
        margin-bottom: 1rem;
    }

    .project img {
        height: 27rem;
        width: 27rem;
    }

    .project p {
        font-size: 35px;
        color: #3e497a;
    }

    .project .activity {
        margin-bottom: 2rem;
        margin-top: 2rem;
        font-size: 20px;
        color: #3e497a;
    }

}

@media only screen and (max-width: 550px) {

    .project {
        height: calc(120vh - 100px);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .project h1 {
        margin-top: 10rem;
        margin-bottom: 5rem;
    }

    .project p {
        font-size: 30px;
        color: #3e497a;
    }

    .project img {
        height: 27rem;
        width: 27rem;
    }


    .project .activity {
        margin-bottom: 2rem;
        margin-top: 2rem;
        font-size: 17px;
        color: #3e497a;
    }

}

@media only screen and (max-width: 450px) {
    .project h1 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .project img {
        height: 22rem;
        width: 22rem;
    }

    .project p {
        font-size: 22px;
        color: #3e497a;
    }

    .project .activity {
        margin-bottom: 2rem;
        margin-top: 2rem;
        font-size: 12px;
        color: #3e497a;
    }

}