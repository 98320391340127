.navbar {
    width: 100%;
    height: 100px;
    background: #2a1161;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.click:hover {
    color: gold;
    transform: scale(1.1);
}

.navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
    font-size: 25px;
}

.toggle-button {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.toggle-button svg {
    font-size: 50px;
}

.toggle-button button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

#open {
    height: 100vh;
    animation: fadeIn 1s ease-in-out;
}

#open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
    margin-bottom: 100px;
}

#open a {
    width: 100%;
    text-align: center;
}

#open .logo {
    display: flex;
    height: 10rem;
    border-radius: 5rem;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 3rem;
}

@media only screen and (max-width: 900px) {
    .navbar a {
        width: 70px;
    }

    .logo {
        diplay: none;
    }
}

@media only screen and (max-width: 600px) {
    .toggle-button {
        display: flex;
    }

    #close a {
        display: none;
    }

    .logo {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .toggle-button {
        display: none;
    }

    .hiddenLinks {
        display: none;
    }

    #open {
        height: 100px;
    }

    #open .links {
        flex-direction: row;
    }

    .logo {
        display: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0.8;
        transform: translateY(-2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
